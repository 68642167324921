<template>
    <v-card flat class="pr-4 pb-4 pl-4">
        <tab-title :title="$t('house.heading1', {name: entityTitle, memberid: getActingMemberid}).toString()"/>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >

            <v-row v-for="(row, rowIndex) in rows" :key="`row-${rowIndex}`">
                <v-col
                    class="py-0 pl-4 mt-1"
                    v-for="(item, itemIndex) in row.row"
                    :key="`model-${itemIndex}`"
                    :cols="item.cols"
                >
                    <component
                        :is="`v-${item.element}`"
                        :value="items[item.source]"
                        :v-model="items[item.source]"
                        :readonly="item.readonly"
                        :label="item.label"
                    />
                </v-col>
            </v-row>
        </v-form>
        <v-card-title v-html="$tc('house.heading2', objIds.length, {name: entityTitle})"/>
        <v-divider class="mb-4"/>
        <v-card-text v-if="objIds.length" style="max-width: 700px">
            <v-chip
                v-for="(houseObjid, houseObjidIndex) in objIds"
                :key="`houseObjidIndex-${houseObjidIndex}`"
                class="mr-2 mb-2 elevation-3"
                style="min-width: 72px"
                :color="getColors.secondary2"
                text-color="white"
                @click="goToProperty(houseObjid)"
            >
                <v-icon left>
                    mdi-home-circle-outline
                </v-icon>
                {{ houseObjid }}
            </v-chip>
        </v-card-text>
    </v-card>
</template>

<script>
import TabTitle from '@/components/Allgemein/Tabtitel.vue'
import { mapGetters } from 'vuex'
import Form from '@/components/Formulare/Formular.vue'
import { VTextarea } from 'vuetify/lib'

export default {
    name: 'BasicDataTab',
    components: {
        TabTitle,
        Form,
        VTextarea,
    },
    props: {
        items: Object,
        entityTitle: String,
    },
    data() {
        return {
            valid: true,
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getActingMemberid',
            'getTab',
        ]),
        objIds: function () {
            return this.items['obj_ids'] || []
        },
        rows: function () {
            return [
                {
                    row: [
                        {
                            label: this.$i18n.t('common.labels.description'),
                            element: 'textarea',
                            type: 'text',
                            source: 'hauslng_beschreibung',
                            cols: 10,
                            readonly: true,
                        },
                    ],
                },
                {
                    row: [
                        {
                            label: this.$i18n.t('common.labels.shortDescription'),
                            element: 'textarea',
                            type: 'text',
                            source: 'hauslng_kurztext',
                            cols: 10,
                            readonly: true,
                        },
                    ],
                },
            ]
        },
    },
    methods: {
        goToProperty(houseObjid) {
            this.$router.push({ path: `/property/${ houseObjid }` })
        },
    },
}
</script>
