import communicate from '@/services/communicate.service'

export default {
    /**
     *
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getHousesByMember(payload) {
        return await communicate.request(enums.GET, 'houses/' + payload.memberid)
    },

    /**
     *
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getHouseByMemberAndHouseId(payload) {
        return await communicate.request(enums.GET, 'house/' + payload.memberid + '/' + payload.houseid)
    },

    /**
     *
     * @returns {Promise<*|{warning}|{body}>}
     */
    async getHouseImages(payload) {
        return await communicate.request(enums.GET, 'house/images/' + payload.memberid + '/' + payload.houseid)
    },
}