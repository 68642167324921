<template>
    <div class="w-100 pl-4 mb-4">
        <v-card class="pt-2" outlined>
            <v-card-title v-html="`${house.haus_titel} | ID ${house.haus_id}`"/>
            <v-card-text v-html="$t('house.subheading')"/>
            <v-divider/>
            <v-tabs
                v-model="tab"
                :background-color="getColors.primary"
                dark
                show-arrows
                color="#ffffff"
                center-active
            >
                <v-tabs-slider :color="getColors.secondary1"/>
                <v-tab
                    v-for="(tabItem, index) in tabItems"
                    :key="`tabItem-${index}`"
                    :disabled="tabItem.disabled"
                    @change="tab = index"
                >
                    <v-progress-circular
                        v-if="tabItem.disabled"
                        class="mr-1"
                        indeterminate
                        size="16"
                    />
                    <v-icon
                        v-else
                        class="mr-1"
                        small
                    >
                        {{ tabItem.icon }}
                    </v-icon>
                    {{ tabItem.title }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item
                    class="pl-4"
                    v-for="(tabItem, index) in tabItems"
                    :key="`tabItem-${index}`"
                >
                    <component
                        v-bind:is="tabItem.component"
                        entity-type="house"
                        :items="tabItem.items"
                        :entity-id="house['haus_id']"
                        :entity-title="house['haus_titel']"
                        :sort-images="house['haus_sortimages']"
                    />
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BasicDataTab from '@/components/Haus/Hausstammdaten.vue'
import PhotosTab from '@/components/Allgemein/Fotos.vue'
import HousesService from '@/services/houses.service'

export default {
    name: 'House',
    components: {
        BasicDataTab,
        PhotosTab,
    },
    metaInfo() {
        return {
            title: `${ this.house['haus_titel'] } (${ this.house['haus_id'] })`,
        }
    },
    sockets: {
        async houseUpdate(data) {
            if (data.memberid === this.$store.getters.getActingMemberid) {
                await this.getHouse()
                await this.bannerMessageForSocketEvent('houses', data.memberid)
            }
        },
        async houseImagesUpdate(data) {
            if (data.memberid === this.$store.getters.getActingMemberid) {
                await this.getHouseImages()
                await this.bannerMessageForSocketEvent('house images', data.memberid)
            }
        },
    },
    data: function () {
        return {
            house: {},
            houseImages: [],
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getTab',
            'getLoaders',
        ]),
        houseId: function () {
            return parseInt(this.$route.params.houseid)
        },
        tab: {
            get() {
                return this.getTab
            },
            set(newValue) {
                this.$store.commit('SET_TAB', newValue)
            },
        },
        tabItems: function () {
            return [
                {
                    title: this.$i18n.t('common.tabs.basicData'),
                    icon: 'mdi-home-outline',
                    component: 'BasicDataTab',
                    disabled: this.getLoaders.house,
                    items: this.house,
                },
                {
                    title: this.$i18n.t('common.tabs.photos'),
                    icon: 'mdi-image-multiple',
                    component: 'PhotosTab',
                    disabled: this.getLoaders.houseImages,
                    items: this.houseImages,
                },
            ]
        },
    },
    methods: {
        async bannerMessageForSocketEvent(dataType, memberid) {
            await this.$store.dispatch('socketEventBanner', {
                message: `Some ${ dataType } changed for member ${ memberid }!`,
                autoHide: true,
            })
        },
        async getHouse() {
            await this.$store.dispatch('setLoaders', { house: true })
            this.house = await HousesService.getHouseByMemberAndHouseId({
                memberid: this.$store.getters.getActingMemberid,
                houseid: this.houseId,
            })
            await this.$store.dispatch('setLoaders', { house: false })
        },
        async getHouseImages() {
            await this.$store.dispatch('setLoaders', { houseImages: true })
            this.houseImages = await HousesService.getHouseImages({
                memberid: this.$store.getters.getActingMemberid,
                houseid: this.houseId,
            })
            await this.$store.dispatch('setLoaders', { houseImages: false })
        },
    },
    async mounted() {
        if (this.$route.query.hasOwnProperty('target') && this.$route.query.target === 'blank')
            this.$store.commit('SET_TAB', 0)

        this.tabItems[0].disabled = false

        await Promise.all([
            this.getHouse(),
            this.getHouseImages(),
        ])
    },

}
</script>
